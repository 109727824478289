import React from "react";
import { StyledContactForm, StyledContactFormIframe } from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";
import Intro from "../Intro/Intro";

export default ({
  themeColor, title, bigText, formUrl, paddingTop,
}) => {
  return (
    <StyledContactForm paddingTop={paddingTop} hasGradient={themeColor?.black} id="contactForm">
      {(title || bigText) && (
        <Intro title={title} bigText={bigText} />
      )}
      <StyledGridRow>
        <StyledContactFormIframe title="Contact form" scrolling="no" src={formUrl} />
      </StyledGridRow>
    </StyledContactForm>
  );
};
